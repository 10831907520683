<template>
  <eval-alt-wrap
      title="Compte rendu d’évaluation">
      <template v-slot:print>
        <a href="#" class="nav-link" @click.prevent="printSynthesis">
          <img src="@/assets/icon-plein-print.svg" alt="">
          <span><font-awesome-icon v-show="loadingPrint" :icon="['fas', 'spinner']" spin/> Imprimer</span>
        </a>
      </template>
    <loader :loader="loading"/>

    <Identity
        ref="identity"/>
    <Demand
        ref="demand"/>
    <Habitat
        ref="habitat"/>
    <Entourage
        ref="entourage"/>
    <Autonomy
        ref="autonomy"/>
    <Health
        ref="health"/>
    <ObjectivesActions
        ref="objectivesActions"/>
    <Intervention
        ref="intervention"/>
    <Planning
        ref="planning"/>
    <Contact
        ref="contact"/>

    <div class="container devis-bottom--table-wrap main-table">
      <table class="table devis-bottom--table">
        <tbody>
          <tr class="main text-center">
            <td>
                <button class="btn btn-success" @click="printSynthesis">
                  Générer la synthèse
                  <font-awesome-icon v-show="loadingPrint" :icon="['fas', 'spinner']" spin/>
                </button>
            </td>
            <td>
              <router-link class="btn btn-warning" :to="{name:'project-life'}">
                Modifier le plan d'aide
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </eval-alt-wrap>
</template>
<script>

import Identity from "@/views/Evaluation/CR/Identity";
import Demand from "@/views/Evaluation/CR/Demand";
import Habitat from "@/views/Evaluation/CR/Habitat";
import Entourage from "@/views/Evaluation/CR/Entourage";
import Autonomy from "@/views/Evaluation/CR/Autonomy";
import Health from "@/views/Evaluation/CR/Health";
import ObjectivesActions from "@/views/Evaluation/CR/ObjectivesActions";
import Intervention from "@/views/Evaluation/CR/Intervention";
import Planning from "@/views/Evaluation/CR/Planning";
import Contact from "@/views/Evaluation/CR/Contact";
import {mapActions, mapMutations, mapState} from "vuex";
import {API_URL} from "@/components/Util/EnvVariable";


export default {
  name: "CR",
  components:{
    Identity,
    Demand,
    Habitat,
    Entourage,
    Autonomy,
    Health,
    ObjectivesActions,
    Intervention,
    Planning,
    Contact
  },
  data() {
    return {
      loadingPrint: false,
      setTimeoutSave: null
    }
  },
  mounted() {
    this.getAggirQuestionList({limit: 0});
    this.getTreatment(this.$route.params.evaluationId);
    this.getEvaluationMaterials({id: this.$route.params.evaluationId}).then(() => {
      this.getSyntheseGoals(this.$route.params.evaluationId);
    });
    this.autoSave();
  },
  methods: {
    ...mapActions('AggirQuestion', ['getAggirQuestionList']),
    ...mapActions('Evaluation', [
      'getTreatment',
      'postGenerageSynthese',
      'getEvaluationMaterials',
      'getSyntheseGoals'
    ]),
    ...mapMutations('Evaluation', ['setEvaluationProperty']),
    addDataToExportData(data, dataToAdd) {
      return {
        ...data,
        ...dataToAdd
      }
    },
    async printSynthesis(withDownload = true) {
      this.loadingPrint = true;
      let data = {};

      const identity = this.$refs.identity;
      const demand = this.$refs.demand;
      const habitat = this.$refs.habitat;
      const entourage = this.$refs.entourage;
      const autonomy = this.$refs.autonomy;
      const health = this.$refs.health;
      const objectivesActions = this.$refs.objectivesActions;
      const intervention = this.$refs.intervention;
      const planning = this.$refs.planning;
      const contact = this.$refs.contact;

      if(identity) {
        data = this.addDataToExportData(data, identity.exportSynthesis());
      }
      if(demand) {
        data = this.addDataToExportData(data, demand.exportSynthesis());
      }
      if(habitat) {
        data = this.addDataToExportData(data, habitat.exportSynthesis());
      }
      if(entourage) {
        data = this.addDataToExportData(data, entourage.exportSynthesis());
      }
      if(autonomy) {
        data = this.addDataToExportData(data, autonomy.exportSynthesis());
      }
      if(health) {
        data = this.addDataToExportData(data, health.exportSynthesis());
      }
      if(health) {
        data = this.addDataToExportData(data, health.exportSynthesis());
      }
      if(objectivesActions) {
        data = this.addDataToExportData(data, objectivesActions.exportSynthesis());
      }
      if(intervention) {
        data = this.addDataToExportData(data, intervention.exportSynthesis());
      }
      if(planning) {
        data = this.addDataToExportData(data, await planning.exportSynthesis());
      }
      if(contact) {
        data = this.addDataToExportData(data, await contact.exportSynthesis());
      }

      let formData = new FormData();
      Object.keys(data).forEach(item => {
        formData.append(item, data[item]);
      });
      return this.postGenerageSynthese({data: formData}).then((data) => {
        this.loadingPrint = false;
        if (withDownload) {
          window.open(`${API_URL}/download/synthese/${data.id}`, '_blank');
        }
        return data;
      }).catch((err) => {
        this.loadingPrint = false;
        throw err;
      });
    },
    autoSave(){
      if(this.setTimeoutSave){
        clearTimeout(this.setTimeoutSave);
      }
      this.setTimeoutSave = setTimeout(() => {
        this.printSynthesis(false);
        this.autoSave();
      }, 1200000); //2 minutes
    }
  },
  computed: {
    ...mapState("Evaluation", ["loading"])
  },
  beforeDestroy() {
    if(this.setTimeoutSave){
      clearTimeout(this.setTimeoutSave);
    }
  }
};
</script>