<template>
  <div class="soin-item">
    <div class="soin-item--title" @click.prevent="toggleActive">
      <h1>Nutrition</h1>
      <button class="btn" :class="{ inverted: !active }">
        <img src="@/assets/chevron-up.svg" alt />
      </button>
    </div>
    <div class="soin-item--content">
      <eval-card-check
        :showContentSlot="true"
        :checked="
          sn01weight !== null &&
          sn01weight !== '' &&
          sn01size !== null &&
          sn01size !== ''
        "
        title="IMC"
        v-if="active"
      >
        <template v-slot:content>
          <div class="w-100 row">
            <div class="col-6">
              <div class="soin-item--letter-wrap">
                <div class="eval-card--form input">
                  <div class="form-group eval-form eval-form--input">
                    <input
                      v-model="sn01weight"
                      type="number"
                      class="form-control"
                      placeholder="Poids (kilo)"
                      style="
                        height: 40px;
                        font-size: 18px;
                        text-align: center;
                        width: 100px;
                        max-width: 100px;
                      "
                      @blur="onBlurSn01weight"
                    />
                  </div>
                </div>
              </div>
              <div class="soin-item--letter-wrap">
                <div class="eval-card--form input">
                  <div class="form-group eval-form eval-form--input">
                    <input
                      v-model="sn01size"
                      type="number"
                      class="form-control"
                      placeholder="Taille (cm)"
                      style="
                        height: 40px;
                        font-size: 18px;
                        text-align: center;
                        width: 100px;
                        max-width: 100px;
                      "
                      @blur="onBlurSn01size"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              IMC :
              <span
                class="font-weight-bold"
                style="font-size: 40px; padding: 40px"
                >{{ imc }}</span
              >
            </div>
          </div>
        </template>
      </eval-card-check>

      <template v-if="
          sn01weight !== null &&
          sn01weight !== '' &&
          sn01size !== null &&
          sn01size !== ''">
        <eval-card-check
          :showMainSlot="true"
          v-show="imc <= 16"
          :has-check="false"
          :checked="false"
          class="theme-red"
          title="Interprétation IMC"
        >
          <template v-slot:main>
            {{ `${getPeopleStr} est en dénutrition sévère.` }}
          </template>
        </eval-card-check>

        <eval-card-check
          :showMainSlot="true"
          v-show="imc > 16 && imc <= 18"
          :has-check="false"
          :checked="false"
          class="theme-red"
          title="Interprétation IMC"
          :body="`${getPeopleStr} est en dénutrition modérée.`"
        >
          <template v-slot:main>
            {{ `${getPeopleStr} est en dénutrition modérée.` }}
          </template>
        </eval-card-check>

        <eval-card-check
          :showMainSlot="true"
          v-show="imc > 18 && imc <= 25"
          :has-check="false"
          :checked="false"
          class="theme-red"
          title="Interprétation IMC"
        >
          <template v-slot:main>
            <p>
              {{ `${getPeopleStr} est de poids normal.` }}
            </p>
          </template>
        </eval-card-check>

        <eval-card-check
          :showMainSlot="true"
          v-show="imc > 25 && imc <= 30"
          :has-check="false"
          :checked="false"
          class="theme-red"
          title="Interprétation IMC"
          :body="`${getPeopleStr} est en surpoids.`"
        >
          <template v-slot:main>
            {{ `${getPeopleStr} est en surpoids.` }}
          </template>
        </eval-card-check>

        <eval-card-check
          :showMainSlot="true"
          v-show="imc > 30 && imc <= 40"
          :has-check="false"
          :checked="false"
          class="theme-red"
          title="Interprétation IMC"
          :body="`${getPeopleStr} présente une obésité modérée.`"
        >
          <template v-slot:main>
            {{ `${getPeopleStr} présente une obésité modérée.` }}
          </template>
        </eval-card-check>

        <eval-card-check
          :showMainSlot="true"
          v-show="imc > 40"
          :has-check="false"
          :checked="false"
          class="theme-red"
          title="Interprétation IMC"
          :body="`${getPeopleStr} présente une obésité sévère.`"
        >
          <template v-slot:main>
            {{ `${getPeopleStr} présente une obésité sévère.` }}
          </template>
        </eval-card-check>
      </template>

      <eval-card-check
        :showContentSlot="true"
        :checked="
          getScoreQuestionAggirMoveIn !== null &&
          getScoreQuestionAggirMoveOut !== null
        "
        title="Motricité"
        v-if="
          (getScoreQuestionAggirMoveIn || getScoreQuestionAggirMoveOut) &&
          active
        "
      >
        <template v-slot:content>
          <div
            class="soin-item--letter-wrap"
            v-if="getScoreQuestionAggirMoveIn"
          >
            <span class="soin-item--letter-label">Déplacement interne :</span>
            <span
              :class="[
                'cuisine-item--letter-icon letter-icon-sm',
                styleScoreAggirQuestionMovIn,
              ]"
              >{{ getScoreQuestionAggirMoveIn }}</span
            >
          </div>
          <div
            class="soin-item--letter-wrap"
            v-if="getScoreQuestionAggirMoveOut"
          >
            <span class="soin-item--letter-label">Déplacement externe :</span>
            <span
              :class="[
                'cuisine-item--letter-icon letter-icon-sm',
                styleScoreAggirQuestionMovOut,
              ]"
              >{{ getScoreQuestionAggirMoveOut }}</span
            >
          </div>
        </template>
      </eval-card-check>

      <eval-card-check
        :showMainSlot="true"
        v-show="mma"
        :has-check="false"
        :checked="false"
        class="theme-red"
        title="Interprétation MNA"
      >
        <template v-slot:main>
          {{ mma }}
        </template>
      </eval-card-check>

      <eval-card-check
        :showContentSlot="true"
        :checked="sn03 !== null && sn03 !== ''"
        title="La personne, est-elle nourrie par une sonde ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sn03" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <template v-if="sn03 === 'r1'">
        <eval-card-check
          :showContentSlot="true"
          :checked="sn15 !== null && sn15 !== ''"
          title="Quel type de sonde est utilisé pour votre nutrition ?"
          v-if="active"
        >
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="sn15" name id>
                    <option value="r1">
                      Sonde naso-gastrique/naso-jejunale
                    </option>
                    <option value="r2">
                      Sonde de gastrostomie/jejunostomie
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>
        <eval-card-check
          :showContentSlot="true"
          :checked="sn16 !== null && sn16 !== ''"
          title="Continuez-vous à vous hydrater par la bouche ?"
          v-if="active"
        >
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="sn16" name id>
                    <option value="r1">Oui</option>
                    <option value="r2">Non</option>
                    <option value="r3">NSP</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>
        <eval-card-check
          :showContentSlot="true"
          :checked="sn17 !== null && sn17.length > 0"
          title="Qui s'occupe de votre nutrition entérale ?"
          v-if="active"
        >
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[
                    'Personne elle-même',
                    'Conjoint/famille',
                    'Infirmière/service de soin',
                    'NSP',
                  ]"
                >
                  <template slot="content">
                    <div
                      class="id-check--option"
                      v-for="(option, index) in [
                        { value: 'r1', label: 'Personne elle-même' },
                        { value: 'r2', label: 'Conjoint/famille' },
                        { value: 'r3', label: 'Infirmière/service de soin' },
                        { value: 'r4', label: 'NSP' },
                      ]"
                      :key="index"
                    >
                      <input
                        class="id-check--input"
                        :id="`sn17-option-${index}`"
                        type="checkbox"
                        :value="option.value"
                        v-model="sn17"
                      />
                      <label :for="`sn17-option-${index}`">
                        <span class="id-check--indicator">{{
                          option.label
                        }}</span>
                      </label>
                    </div>
                  </template>
                </eval-form-check-alt>
              </div>
            </div>
          </template>
        </eval-card-check>
      </template>

      <template v-if="sn03 === 'r2'">
        <eval-card-check
          :showContentSlot="true"
          :checked="sn04 !== null && sn04 !== ''"
          title="Avez-vous présenté une baisse des prises alimentaires ces 3 derniers mois ?"
          v-if="active"
        >
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="sn04" name id>
                    <option value="r1">
                      Baisse sévère des prises alimentaires
                    </option>
                    <option value="r2">
                      Legère baisse des prises alimentaires
                    </option>
                    <option value="r3">
                      Pas de baisse des prises alimentaires
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>

        <eval-card-check
          :showContentSlot="true"
          :checked="sn05 !== null && sn05 !== ''"
          title="Avez-vous eu une perte récente de poids au cours des 3 derniers mois ?"
          v-if="active"
        >
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="sn05" name id>
                    <option value="r1">Perte de poids > 3 kg</option>
                    <option value="r2">Ne sait pas</option>
                    <option value="r3">Perte de poids entre 1 et 3 kg</option>
                    <option value="r4">Pas de perte de poids</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>

        <eval-card-check
          :showMainSlot="true"
          :has-check="false"
          :checked="false"
          class="theme-red"
          v-show="resultIcopeNutrition"
          title="RÉSULTAT ICOPE NUTRITION"
        >
          <template v-slot:main>
            {{ resultIcopeNutrition }}
          </template>
        </eval-card-check>
      </template>

      <eval-card-check
        :showContentSlot="true"
        :checked="sn06 !== null && sn06 !== ''"
        title="Avez-vous eu une maladie aigüe ou un stress psychologique au cours des 3 derniers mois ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sn06" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>

      <template v-if="sn03 === 'r2'">
        <eval-card-check
          :showContentSlot="true"
          :checked="sn07 !== null && sn07 !== ''"
          title="Suivez-vous un régime prescrit par un médecin ?"
          v-if="active"
        >
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="sn07" name id>
                    <option value="r1">Oui</option>
                    <option value="r2">Non</option>
                    <option value="r3">NSP</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>

        <eval-card-check
          :showContentSlot="true"
          :checked="sn08 !== null && sn08.length > 0"
          title="Si oui quel type de régime (plusieurs choix possibles)"
          v-if="active"
        >
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form">
                <eval-form-check-alt
                  inputType="checkbox"
                  subtitle=""
                  :options="[]"
                >
                  <template slot="content">
                    <div
                      class="id-check--option"
                      v-for="(option, index) in [
                        { value: 'r1', label: 'Anti cholestérol' },
                        { value: 'r2', label: 'Anti triglycérides' },
                        { value: 'r3', label: 'Anti diarrhéique' },
                        { value: 'r4', label: 'Diabétique' },
                        { value: 'r5', label: 'Épargne colique, constipation' },
                        { value: 'r6', label: 'Hypo protidique' },
                        { value: 'r7', label: 'Hyper protidique' },
                        { value: 'r8', label: 'Sans sel' },
                        { value: 'r9', label: 'Hypo sodé' },
                      ]"
                      :key="index"
                    >
                      <input
                        class="id-check--input"
                        :id="`sn08-option-${index}`"
                        type="checkbox"
                        :value="option.value"
                        v-model="sn08"
                      />
                      <label :for="`sn08-option-${index}`">
                        <span class="id-check--indicator">{{
                          option.label
                        }}</span>
                      </label>
                    </div>
                  </template>
                </eval-form-check-alt>
              </div>
            </div>
          </template>
        </eval-card-check>

        <eval-card-check
          :showContentSlot="true"
          :checked="sn09 !== null && sn09 !== ''"
          title="Êtes-vous allergique à certains aliments ?"
          v-if="active"
        >
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="sn09" name id>
                    <option value="r1">Oui</option>
                    <option value="r2">Non</option>
                    <option value="r3">NSP</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>

        <eval-card-check
          :showContentSlot="true"
          :checked="sn10 !== null && sn10 !== ''"
          title="Si oui, lesquels ?"
          v-if="sn09 === 'r1' && active"
        >
          <template v-slot:content>
            <div class="w-100">
              <div class="eval-card--form input">
                <div class="form-group eval-form eval-form--input">
                  <textarea
                    v-model="sn10"
                    class="form-control"
                    placeholder="Saisir une réponse"
                    style="width: 100%; max-width: 100%"
                    @blur="onBlurSn10"
                  ></textarea>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>

        <eval-card-check
          :showContentSlot="true"
          :checked="sn11 !== null && sn11 !== ''"
          title="Éprouvez-vous des difficultés pour mâcher ou avaler les aliments ?"
          v-if="active"
        >
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="sn11" name id>
                    <option value="r1">Oui</option>
                    <option value="r2">Non</option>
                    <option value="r3">NSP</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>

        <eval-card-check
          :showContentSlot="true"
          :checked="sn12 !== null && sn12 !== ''"
          title="Si oui, lesquels ?"
          v-if="sn11 === 'r1' && active"
        >
          <template v-slot:content>
            <div class="w-100">
              <div class="eval-card--form input">
                <div class="form-group eval-form eval-form--input">
                  <textarea
                    v-model="sn12"
                    class="form-control"
                    placeholder="Saisir une réponse"
                    style="width: 100%; max-width: 100%"
                    @blur="onBlurSn12"
                  ></textarea>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>

        <eval-card-check
          :showContentSlot="true"
          :checked="sn13 !== null && sn13 !== ''"
          title="Sous quelle forme de texture les aliments vous sont-ils présentés ?"
          v-if="active"
        >
          <template v-slot:content>
            <div class="d-flex">
              <div class="eval-card--form select">
                <div class="form-group eval-form eval-form--select">
                  <select class="form-control" v-model="sn13" name id>
                    <option value="r1">Normale</option>
                    <option value="r2">Hachée</option>
                    <option value="r3">Mixée</option>
                  </select>
                </div>
              </div>
            </div>
          </template>
        </eval-card-check>
      </template>

      <eval-card-check
        :showContentSlot="true"
        :checked="sn14 !== null && sn14 !== ''"
        title="Portez-vous un appareil dentaire ?"
        v-if="active"
      >
        <template v-slot:content>
          <div class="d-flex">
            <div class="eval-card--form select">
              <div class="form-group eval-form eval-form--select">
                <select class="form-control" v-model="sn14" name id>
                  <option value="r1">Oui</option>
                  <option value="r2">Non</option>
                  <option value="r3">NSP</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </eval-card-check>
    </div>
  </div>
</template>
<script>
import EvalCardCheck from "@/components/Eval/EvalCardCheck";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
  getAnswerTotal,
  getClassicRuleScore,
} from "@/components/Util/UtilFunction";
import SubQuestionExtend from "@/views/Evaluation/Soin/SubQuestionExtend";
import EvalFormCheckAlt from "@/components/Eval/FormCheckAlt";
import {
  imcCalcul,
  mnaCalcul,
  getICOPENutrition,
} from "@/components/Eval/SoinCalcul";

export default {
  extends: SubQuestionExtend,
  components: {
    EvalCardCheck,
    EvalFormCheckAlt,
  },
  data: () => ({
    active: true,
  }),
  methods: {
    ...mapMutations("Evaluation", ["setTreatmentProperty"]),
    ...mapActions("Evaluation", ["saveTreatment"]),
    getClassicRuleScore,
    getAnswerTotal,
    onBlurSn01weight() {
      this.sn01weight = parseInt(this.sn01weight);
      this.saveTreatment({
        id: "nutrition",
        name: "sn01weight",
        evaluationId: this.$route.params.evaluationId,
      });
    },
    onBlurSn01size() {
      this.sn01size = parseInt(this.sn01size);
      this.saveTreatment({
        id: "nutrition",
        name: "sn01size",
        evaluationId: this.$route.params.evaluationId,
      });
    },
    onBlurSn10() {
      this.saveTreatment({
        id: "nutrition",
        name: "sn10",
        evaluationId: this.$route.params.evaluationId,
      });
    },
    onBlurSn12() {
      this.saveTreatment({
        id: "nutrition",
        name: "sn12",
        evaluationId: this.$route.params.evaluationId,
      });
    },
    getAggirAnswerScore(questionId) {
      const answer = this.evaluation.answerList
        ? this.evaluation.answerList.find(
            (ans) => ans.question.id === questionId
          )
        : null;
      if (answer) {
        return answer.itemScore;
      }
      return null;
    },
  },
  computed: {
    ...mapGetters("AggirQuestion", [
      "getScoreQuestionAggirMoveOut",
      "getScoreQuestionAggirMoveIn",
    ]),
    ...mapState("Evaluation", ["evaluation", "nutrition"]),
    styleScoreAggirQuestionMovIn() {
      if (!this.getScoreQuestionAggirMoveIn) {
        return "";
      }
      if (this.getScoreQuestionAggirMoveIn === "A") {
        return "letter-icon-green";
      }
      if (this.getScoreQuestionAggirMoveIn === "B") {
        return "letter-icon-amber";
      }
      if (this.getScoreQuestionAggirMoveIn === "C") {
        return "letter-icon-red";
      }
      return "";
    },
    styleScoreAggirQuestionMovOut() {
      if (!this.getScoreQuestionAggirMoveOut) {
        return "";
      }
      if (this.getScoreQuestionAggirMoveOut === "A") {
        return "letter-icon-green";
      }
      if (this.getScoreQuestionAggirMoveOut === "B") {
        return "letter-icon-amber";
      }
      if (this.getScoreQuestionAggirMoveOut === "C") {
        return "letter-icon-red";
      }
      return "";
    },
    resultIcopeNutrition() {
      return getICOPENutrition(this.sn04, this.sn05);
    },
    imc() {
      return imcCalcul(this.sn01weight, this.sn01size);
    },
    mma() {
      const mna = mnaCalcul(
        this.getPeopleStr,
        this.imc,
        this.sn03,
        this.sn04,
        this.sn05,
        this.sn06,
        this.getScoreQuestionAggirMoveIn,
        this.getAggirAnswerScore(23)
      );
      if (mna) {
        return mna.interpretation;
      }
      return null;
    },
    sn01size: {
      get() {
        return this.nutrition.sn01size.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn01size",
          value: value,
        });
      },
    },
    sn01weight: {
      get() {
        return this.nutrition.sn01weight.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn01weight",
          value: value,
        });
      },
    },
    sn02: {
      get() {
        return this.nutrition.sn02.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn02",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn02",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn03: {
      get() {
        return this.nutrition.sn03.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn03",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn03",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn04: {
      get() {
        return this.nutrition.sn04.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn04",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn04",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn05: {
      get() {
        return this.nutrition.sn05.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn05",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn05",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn06: {
      get() {
        return this.nutrition.sn06.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn06",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn06",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn07: {
      get() {
        return this.nutrition.sn07.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn07",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn07",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn08: {
      get() {
        return this.nutrition.sn08.response
          ? this.nutrition.sn08.response.split(",")
          : [];
      },
      set(value) {
        const prevValue = this.nutrition.sn08.response;
        this.setTreatmentProperty({
          name: "sn08",
          value: value.join(","),
        });
        if (value.join(",") !== prevValue) {
          this.saveTreatment({
            id: "nutrition",
            name: "sn08",
            evaluationId: this.$route.params.evaluationId,
          });
        }
      },
    },
    sn09: {
      get() {
        return this.nutrition.sn09.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn09",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn09",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn10: {
      get() {
        return this.nutrition.sn10.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn10",
          value: value,
        });
      },
    },
    sn11: {
      get() {
        return this.nutrition.sn11.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn11",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn11",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn12: {
      get() {
        return this.nutrition.sn12.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn12",
          value: value,
        });
      },
    },
    sn13: {
      get() {
        return this.nutrition.sn13.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn13",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn13",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn14: {
      get() {
        return this.nutrition.sn14.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn14",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn14",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn15: {
      get() {
        return this.nutrition.sn15.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn15",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn15",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn16: {
      get() {
        return this.nutrition.sn16.response;
      },
      set(value) {
        this.setTreatmentProperty({
          name: "sn16",
          value: value,
        });
        this.saveTreatment({
          id: "nutrition",
          name: "sn16",
          evaluationId: this.$route.params.evaluationId,
        });
      },
    },
    sn17: {
      get() {
        return this.nutrition.sn17.response
          ? this.nutrition.sn17.response.split(",")
          : [];
      },
      set(value) {
        const prevValue = this.nutrition.sn17.response;
        this.setTreatmentProperty({
          name: "sn17",
          value: value.join(","),
        });
        if (value.join(",") !== prevValue) {
          this.saveTreatment({
            id: "nutrition",
            name: "sn17",
            evaluationId: this.$route.params.evaluationId,
          });
        }
      },
    },
  },
};
</script>
